<script setup>
const localePath = useLocalePath();
</script>

<template>
  <div>
    <navigation />
    <div class="font-sf-pro">
      <div class="error">
        <div class="error-details">
          <h1
            class="error-title"
            v-text="$t('OOPS')"
          />
          <p v-text="$t('WECANNOTFINDTHEPAGE')" />
          <nuxt-link :to="localePath({ name: 'index' })">
            <sc-button
              primary
              large
              class="mt-8"
              :label="$t('BACKTOHOMEPAGE')"
            />
          </nuxt-link>
        </div>
        <div class="img-container">
          <svg-error-404 class="lg:mx-0 mx-auto" />
        </div>
      </div>
    </div>
    <footer-item />
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/css/functions";

.error {
  @apply container px-4 lg:px-0 grid grid-cols-1 md:grid-cols-2 md:gap-14 md:py-24;
  @media (min-width: 1280px) {
    @apply mx-auto;
    max-width: rem(1120px);
  }
  @media (max-width: 768px) {
    padding-bottom: rem(44px);
  }
}

.error-details {
  @apply flex flex-col justify-center items-center md:items-start order-2 md:order-1 mt-16 md:mt-0;
}

.error-title {
  @apply text-secondary-400 font-bold;
  font-size: rem(64px);
  line-height: rem(76px);
}

p {
  @apply text-secondary-300 mt-6;
  font-size: rem(24px);
  line-height: rem(32px);
  @media (max-width: 768px) {
    @apply font-bold text-center mx-5;
    font-size: rem(20px);
    line-height: rem(30px);
  }
}
.img-container {
  @apply mt-8 md:mt-0 order-1 md:order-2;
}
</style>
